import React, {useEffect} from 'react'
import {graphql} from 'gatsby'
import {useI18next} from "gatsby-plugin-react-i18next";
import * as constants from "../app.constant";
import {env} from "../app.constant";
import deviceStore from "../store/deviceStore";
import LoadingSpinner from "../components/shared/loader";
import Layout from "../components/shared/layout";

export default function DeviceInfoPage() {
  const { navigate } = useI18next()
  
  async function getImeiOrSerialNoByToken(token){
    return await (
        await fetch(`${env.GET_IMEI_OR_SERIAL_NO_BY_TOKEN}?encryptedToken=${token}&countryCode=${env.GATSBY_COUNTRY_CODE}`, {
          method: "GET",
          headers: {
            "x-api-key": env.GET_PRODUCT_LIST_X_API_KEY,
            "Content-Type": "application/json",
          },
        })
    ).json()
  }
  
  function getClientDevice(tokenResponse) {
    const clientDeviceReq = {
      modelKey: tokenResponse.deviceModelFullId,
      partnerId: [env.GATSBY_PARTNER_CODE],
    }
    const clientDeviceRequest = {
      method: 'POST',
      headers: {
        "Content-Type": constants.HEADER_REQUEST.contentType,
        "x-api-key": constants.env.GET_PRODUCT_LIST_X_API_KEY,
      },
      body: JSON.stringify(clientDeviceReq)
    };
    fetch(constants.env.GET_CLIENT_DEVICE_BY_MODEL_KEY_API_URL, clientDeviceRequest).then(res => res.json())
        .then(response => {
          if(response.resultList.length > 0){
            if (response.resultList[0].deviceType === "HANDSET") {
              deviceStore.deviceType = constants.DEVICE_TYPE.MOBILE
              deviceStore.deviceImei = tokenResponse.deviceImei
            } else {
              if (response.resultList[0].deviceType === "WATCH" || response.resultList[0].deviceType === "BUDS") {
                deviceStore.deviceType = constants.DEVICE_TYPE.WEARABLE
              } else {
                deviceStore.deviceType = constants.DEVICE_TYPE.TABLET
              }
              deviceStore.deviceImei = tokenResponse.deviceSerialNumber
            }
          } else {
            // auto populate imei/serial no when no client device found
            const imeiRegex = /^[0-9\b]+$/
            const serialNoRegex = /^[a-zA-Z0-9]+$/
            if (tokenResponse.deviceImei && imeiRegex.test(tokenResponse.deviceImei)) {
              deviceStore.deviceType = constants.DEVICE_TYPE.MOBILE
              deviceStore.deviceImei = tokenResponse.deviceImei
            } else if (tokenResponse.deviceSerialNumber && serialNoRegex.test(tokenResponse.deviceSerialNumber)) {
              deviceStore.deviceType = constants.DEVICE_TYPE.TABLET
              deviceStore.deviceImei = tokenResponse.deviceSerialNumber
            } else {
              console.log("Not valid imei or serial no.");
            }
            console.log("No Client Device Available for modelKey: " + tokenResponse.deviceModelFullId)
          }
          deviceStore.cid = constants.getCid
          navigate(constants.ROUTES.HOME)
        })
        .catch(err => {
          console.log("Get Client Device Exception", err)
          navigate(constants.ROUTES.HOME)
        })
  }
  
  useEffect(() => {
    document.title = '';

    // Auto populate IMEI/SerialNo from token
    if (constants.getCid && constants.getToken) {
      getImeiOrSerialNoByToken(encodeURIComponent(constants.getToken).replace(/%20/g, "%2B")).then( res => {
        if (res.deviceModelFullId && (res.deviceImei || res.deviceSerialNumber)) {
          getClientDevice(res)
        } else {
          navigate(constants.ROUTES.HOME)
        }
      })
    } else {
      navigate(constants.ROUTES.HOME)
    }
  }, [])

  return (
    <Layout header={false} footer={false} isBackBtn={false}>
      <LoadingSpinner isVisible={true}/>
    </Layout>
  )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`